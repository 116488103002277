@import "base";
/*
image: url(/src/img/index/global_navi_01.png);
repeat: no-repeat;
*/
#wrapper {
  width: 100%;
  min-width: 960px;
  -ms-overflow-y: auto;
  overflow-y: auto;
  -ms-overflow-x: hidden;
  overflow-x: hidden;
}
#header {
    position: relative;
    z-index: 2;
    background-color: transparent;
}

#main_visual {
    height: 520px;
    position: relative;
    margin-top: -36px;
    .container {
        width: 1920px;
        height: 520px;
        position: absolute;
        left: 50%;
        margin-left: -1920px * 0.5;
        ul {
            position: absolute;
            top: 0;
            left: 0;
            width: 1920px * 4;
            height: 520px;
            li {
                float: left;
                width: 1920px;
                height: 520px + 1;
                &.animation {
                  position: relative;
                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }
            }
        }
        .create_card {
            text-align: center;
            position: relative;
            top: 288px;
            a {
                display: inline-block;
                width: 392px;
                height: 82px;
                background: {
                    image: url(/src/img/index/btn_main_visual.png);
                    repeat: no-repeat;
                }
                text-align: left;
                text-indent: -9999em;
                overflow: hidden;
                &:hover {
                    background-image: url(/src/img/index/btn_main_visual_on.png);
                }
            }
        }
    }
}

#index_global_navi {
    height: 140px;
    background: {
        image: url(/src/img/index/bg_global_navi_01.png);
        repeat: repeat-x;
    }
    .container {
        width: 960px;
        margin: 0 auto;
        overflow: hidden;
        .symbol {
            float: left;
        }
        .global_navi {
            float: left;
            li {
                float: left;
                a {
                  display: inline-block;
                  &:hover {
                    background: {
                        image: url(/src/img/index/bg_global_navi_02.png);
                        repeat: repeat-x;
                    }
                  }
                }
            }
        }
        .status_navi {
            float: left;
            li {
                float: left;
                a {
                    display: inline-block;
                    background: {
                        image: url(/src/img/index/bg_global_navi_03.png);
                        repeat: repeat-x;
                    }
                    &:hover {
                        background: {
                            image: url(/src/img/index/bg_global_navi_04.png);
                            repeat: repeat-x;
                        }
                    }
                }
            }
        }
        #status_navi_log_in {
            display: none;
        }
    }
}

#social {
  background: #f2f2f2;
  border-bottom: 1px solid #e7e7e7;
    .container {
      width: 960px;
      margin: 0 auto;
      padding: 10px 0 12px;
      text-align: right;
    }
}

#about_aiaio_card {
  .container {
    width: 960px;
    margin: auto;
    overflow: hidden;
    padding: 30px 0 40px;
    .pict {
      float: right;
      margin-top: 24px;
      width: auto;
    }
    h2 {
      margin-bottom: 15px;
    }
    p {
      @include font_size(14);
      line-height: 1.4;
      width: 540px;
      margin-bottom: 25px;
      a.a_a {
        font-weight: bold;
        text-decoration: none;
        &:before {
          content: url(/src/img/index/bullet_arrow_right.png);
          margin-right: 5px;
        }
      }
    }
  }
}

#card_collection {
  background: #fcf4df;
  padding: 10px 0 20px;
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  ul.card_list {
    width: 960px;
    margin: auto;
    li {
      display: inline-block;
      margin: 0 0 18px 15px;

    }
  }
  a.btn {
    display: inline-block;
    text-align: left;
    text-indent: -9999em;
    background: {
        image: url(/src/img/index/btn_card_collection.png);
        repeat: no-repeat;
    }
    width: 936px;
    height: 69px;
    &:hover {
      background-image: url(/src/img/index/btn_card_collection_on.png);
    }
  }
}
#create_team_cards {
  background: {
    image: url(/src/img/index/bg_create_team_cards.png);
    repeat: no-repeat;
    position: center top;
  }
  .container {
    width: 960px;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
    h2 {
      margin-bottom: 20px;
    }
    p {
      @include font_size(14);
      line-height: 1.5;
      margin-bottom: 35px;
      em {
        color: #ed1c00;
        font-weight: bold;
        font-style: normal;
      }
    }
    a.btn {
      display: inline-block;
      text-align: left;
      text-indent: -9999em;
      background: {
          image: url(/src/img/index/btn_create_team_cards.png);
          repeat: no-repeat;
      }
      width: 444px;
      height: 69px;
      &:hover {
        background-image: url(/src/img/index/btn_create_team_cards_on.png);
      }
    }
  }
}

#banner_space {
  li {
    text-align: center;
    &.bnr_set_01 {
      background: {
        image: url(/src/img/index/bg_bnr_set_01.png);
      }
    }
    &.bnr_jwbl {
      background: {
        image: url(/src/img/index/bg_bnr_jwbl.png);
      }
    }
  }
}

#topics {
  background: {
    image: url(/src/img/index/bg_topics.png);
  }
  .container {
    margin: 0 auto;
    width: 940px;
    overflow: hidden;
    padding: 40px 0 30px;
    .topics {
      width: 455px;
      float: left;
      h2 {
        @include font_size(17);
        line-height: 19px;
        padding-bottom: 15px;
        border-bottom: 2px solid #ccc;
        margin-bottom: 3px;
      }
      .topics_container {
        width: 455px;
        height: 420px;
        background: #FFF;
        -ms-overflow-y: auto;
        overflow-y: auto;
        -ms-overflow-x: hidden;
        overflow-x: hidden;
      }
    }
    .facebook {
      width: 455px;
      float: right;
      h2 {
        padding-bottom: 15px;
        border-bottom: 2px solid #ccc;
        margin-bottom: 3px;
      }
    }
  }
}