/*setting*/
$baseColor: #015cac;
$bgColor: #fcf4df;
$textColor: #333;

/*mixin*/
@mixin borderBox {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
} //@include borderBox;

@mixin smooth {
	-webkit-transform: translateZ(0px);
	-moz-transform: translateZ(0px);
	-ms-transform: translateZ(0px);
	-o-transform: translateZ(0px);
	transform: translateZ(0px);
	-webkit-overflow-scrolling: touch;
	-moz-overflow-scrolling: touch;
	-ms-overflow-scrolling: touch;
	-o-overflow-scrolling: touch;
	overflow-scrolling: touch;
} //@include smooth;

@mixin transition {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all  0.3s ease;
} //@include transition;

// border-radius
$set_prefix: -webkit-, -moz-, '-ms-', '-o-', '';
@mixin border_radius($br_value: 3px) {
	@each $prefix in $set_prefix {
		#{$prefix}border-radius: $br_value;
	}
} //@include border_radius(10px);

@mixin box_shadow($box_shadow_value...) {
	@each $prefix in $set_prefix {
		#{$prefix}box-shadow: $box_shadow_value;
	}
} //@include box_shadow(rgba(0, 0, 0, 0.5) 0px 2px 2px -2px inset);

@mixin appearance {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
} //@include appearance

@mixin font_size($size: 10, $base: 10) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
} //@include font_size(14);